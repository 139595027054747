import Vue from "vue";
import Vuex from "vuex";
import Cookie from 'vue-cookies'
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    // 存储token
    // Authorization: localStorage.getItem("Authorization")
    //   ? localStorage.getItem("Authorization")
    //   : "",
    Authorization: Cookie.get('Authorization')!=null? Cookie.get('Authorization') : '',
    bottomNav:localStorage.getItem("bottomNav")
    ? localStorage.getItem("bottomNav")
    : "",
  },

  mutations: {
    // 修改token，并将token存入localStorage
    changeLogin(state, user) {
      state.Authorization = user.Authorization;
      // localStorage.setItem("Authorization", user.Authorization);
      Cookie.set("Authorization", user.Authorization);
    },
    saveBottomNav(state,bottomNav){
      state.bottomNav = bottomNav;
      localStorage.setItem("bottomNav", bottomNav);
    },
  },
});

export default store;

<template>
<div class="login-header-box">
  <!-- <van-icon name="arrow-left" class="toBack" @click="toBack"/> -->
  <img class="login-bg" src="../assets/images/login/login_bg.png" alt="">
     
</div>
  
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
  },

  methods: {
    toHome() {
      this.$router.push({
        path: "/",
        query: {
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.login-header-box{
  .toBack{
    position: absolute;
    top:20px;
    left:20px;
    font-size: 40px;
    // color:#fff;
  }
  .login-bg{
    display: block;
    width: 100%;
  }
}

</style>

<template>
  <div class="scrollerBox">
    <div ref="wrapScroll" class="wrapper">
        <ul>
          <li v-for="(a,index) in cpList" :key='index'>
            <img :ref="'wrapScrollImg'+index" :src="a.img" alt="" @click="getImg(index)">
          </li>
        </ul>
    </div>
    <!-- <p>{{wrapScroll.maxScrollX}}</p>
    <p>{{scrollX}}</p>
    <p>{{pj}}</p>
    <p>{{currentIndex}}</p> -->
    <div class="slide">
      <div class="slide-type" :class="{'dq':currentIndex==index}" v-for="(a,index) in cpList" :key='index'></div>
    </div>
    <!-- <div class="scroll_bar" ref="scroll_bar">
      <div class="scroll_bar_item"></div>
    </div> -->
  </div>
</template>
<script>
import BScroll from 'better-scroll'  
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      wrapScroll: {},
      currentIndex:0,
      scrollX:0,
      pj:'',
      bigImg:[],
    };
  },
  props:{
    cpList:{},
  },
  created(){
    
  },
  updated() {
    
  },
  mounted() {
    this.$nextTick(() => {
      this.initScroll();
      this.bigImg=[];
      if(this.cpList){
        this.cpList.forEach((elem, index) => {
        
          this.bigImg.push(elem.img);
        });
      }
    });
    // this.wrapScroll.refresh();
    
  },
  watch:{
    cpList(newName, oldName) {
      console.log('更新一波2')
      this.bigImg=[];
      if(this.cpList){
        this.cpList.forEach((elem, index) => {
        
          this.bigImg.push(elem.img);
        });
      }
      
    },
    
  },
  methods: {
    getImg(index) {
      ImagePreview({
        images: this.bigImg,
        showIndex: true,
        loop: false,
        startPosition: index
      });
    },
    BSrefresh() {
      console.log('更新一波')
      
      this.wrapScroll.scrollTo(0, 0, 0)
      this.wrapScroll.refresh();
    },
    initScroll() {
      
        // const horizontalEl = this.$refs.scroll_bar
        this.wrapScroll = new BScroll(this.$refs.wrapScroll,{
          preventDefault:false,
          scrollX: true,
          scrollY: false,
          useTransition:false,
          bounce: true, // 是否启用回弹动画效果
          momentumLimitDistance: 5, // 符合惯性拖动的最小拖动距离
          probeType:3,
          // scrollbar: {
          //   fade: false,
          //   interactive: false,
          //   // 以下配置项 v2.2.0 才支持
          //   customElements: [horizontalEl],
          //   minSize: 4,
          //   scrollbarTrackClickable: true,
          //   scrollbarTrackOffsetType: 'step',
          //   scrollbarTrackOffsetTime: 300,
          //   // 以下配置项 v2.4.0 才支持
          //   fadeInTime: 250,
          //   fadeOutTime: 500
          // }
        });
        console.log('初始化')
        let tp=this.$refs['wrapScrollImg0'][0]['clientWidth']/2;
        
        
        this.wrapScroll.on('scroll', (position) => {
          this.wrapScroll.refresh();
          this.scrollX=-position.x;
          this.pj=Math.ceil(-((this.wrapScroll.maxScrollX-tp)/this.cpList.length));
          this.currentIndex=parseInt((this.scrollX/this.pj) );
          console.log(this.currentIndex);
          if(this.scrollX<0){
            this.currentIndex=0;
          }
          if(this.scrollX>-this.wrapScroll.maxScrollX){
            this.currentIndex=this.cpList.length-1;
          }
        });
      
    },
  },
};
</script>

<style lang="scss">
.scroll_bar_item{
  background-color: #ffa000!important;
  width: 140px!important;
}
.scrollerBox{
  .slide{
    margin: 3vh auto 0;
    // width: 20%;
    display: flex;
    justify-content: center;
    .slide-type{
      margin: 0 6px;
      width: 14px;
      height: 14px;
      border-radius: 14px;
      background: #e0e0e0;
      &.dq{
        background: #ffea00;
      }
    }
  }
  .wrapper {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
  }
  .content{
    
  }
  .wrapper ul{
    width: max-content;
    height: auto;
  }
  .wrapper li{
    float: left;
    width: 400px;
    height: auto;
    margin-right: 6px;
    img{
      border:1px solid #f5f5f5;
    }
    &:last-child{
      margin-right: 0;
    }
    img{
      width: 100%;
      height: auto;
    }
  }
}

</style>
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect:'/index',
  },
  {
    path: "/login",
    name: "login",
    meta:{title:'登录'},
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/wap",
    name: "wap",
    component: () => import("../views/wap.vue"),
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/index.vue"),
      },
      {
        path: "/mizun",
        name: "mizun",
        component: () => import("../views/mizun.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("../views/news.vue"),
      },
      {
        path: "/article",
        name: "article",
        component: () => import("../views/article.vue"),
      },
      {
        path: "/brand",
        name: "brand",
        component: () => import("../views/brand.vue"),
      },
      {
        path: "/lzg",
        name: "lzg",
        meta:{title:'龙主宫'},
        component: () => import("../views/lzg.vue"),
      },
      {
        path: "/detail",
        name: "detail",
        meta:{title:'珠宝详情'},
        component: () => import("../views/detail.vue"),
      },
      {
        path: "/all_goods",
        name: "all_goods",
        meta:{title:'全部尊品'},
        component: () => import("../views/all_goods.vue"),
      },
      
    ]
  },
  
  
  {
    path: "/user",
    name: "user",
    meta:{title:'个人中心'},
    component: () => import("../views/user.vue"),
  },
  {
    path: "/user_jxs",
    name: "user_jxs",
    meta:{title:'我是经销商'},
    component: () => import("../views/user_jxs.vue"),
  },
  {
    path: "/user_tel",
    name: "user_tel",
    meta:{title:'绑定手机号'},
    component: () => import("../views/user_tel.vue"),
  },
  {
    path: "/user_member",
    name: "user_member",
    meta:{title:'会员权益'},
    component: () => import("../views/user_member.vue"),
  },
  {
    path: "/user_contact",
    name: "user_contact",
    meta:{title:'联系我们'},
    component: () => import("../views/user_contact.vue"),
  },
  {
    path: "/user_collection",
    name: "user_collection",
    meta:{title:'我的收藏'},
    component: () => import("../views/user_collection.vue"),
  },

  
  {
    path: "/findPwd",
    name: "findPwd",
    meta:{title:'忘记密码'},
    component: () => import("../views/login/findPwd.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta:{title:'注册'},
    component: () => import("../views/login/register.vue"),
  },
  
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // if (savedPosition) {
    //   return savedPosition
    // } else {
    //   return { x: 0, y: 0 }
    // }
    return { x: 0, y: 0 }
  },
  
});

// router.beforeEach( (to, from, next) =>{
//   let token=localStorage.getItem("Authorization");
//   if(token || to.path==='/login'){
//     next();
//   }else{
//     next('/login');
//   }
// })

export default router; 

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible";
Vue.config.productionTip = false;
import Vant from "vant";

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import "vant/lib/index.css";
Vue.prototype.toBack = () => router.go(-1);
Vue.prototype.toGo = (go,data) => router.push({
  path:go,
  query:data
});
Vue.use(Vant);

// import vshare from 'vshare'
// Vue.use(vshare)

import animated from "animate.css";
Vue.use(animated);

import titleHeader from './components/titleHeader.vue'
Vue.component("titleHeader", titleHeader);//全局自定义组件
import loginHeader from './components/loginHeader.vue'
Vue.component("loginHeader", loginHeader);//全局自定义组件

import Scroller from './components/Scroller'
Vue.component('Scroller',Scroller)

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
var option = {
  maxSpreadZoom: 1, // 控制预览图最大的倍数，默认是2倍，我这里改成了原图
  fullscreenEl: false, //控制是否显示右上角全屏按钮
  closeEl: true, //控制是否显示右上角关闭按钮
  tapToClose: true, //点击滑动区域应关闭图库
  shareEl: false, //控制是否显示分享按钮
  zoomEl: false, //控制是否显示放大缩小按钮
  counterEl: true, //控制是否显示左上角图片数量按钮
  arrowEl: true,  //控制如图的左右箭头（pc浏览器模拟手机时）
  tapToToggleControls: true, //点击应切换控件的可见性
  clickToCloseNonZoomable: true //点击图片应关闭图库，仅当图像小于视口的大小时
}
Vue.use(preview, option)


// import Vconsole from "vconsole";
// const vConsole = new Vconsole();
// Vue.use(vConsole);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

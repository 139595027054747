import {get, post} from "./request";

// 注册、登录验证码 
export const login = (data) => post("/api/sendMsg/login", data);
// 第一次绑定手机和换绑手机
export const bindTel = (data) => post("/api/sendMsg/bindTel", data);
// 忘记密码的接收验证码
export const forgetPassword = (data) => post("/api/sendMsg/forgetPassword", data);


// 短信验证码登录 和 手机验证码注册
export const loginByTelMsg = (data) => post("/api/user/loginByTelMsg", data);
// 重置密码
export const resetPassword = (data) => post("/api/user/resetPassword", data);
// 账号密码登录
export const loginByAccount = (data) => post("/api/user/loginByAccount", data);
// 注册登录
export const loginByMobile = (data) => post("/api/user/loginByMobile", data);
// 微信登录
export const loginByWx = (data) => post("/api/user/loginByWx", data);
// 获取用户资料
export const getUser = (data) => get("/api/user/user", data);
// 第一次绑定手机号码
export const userBindTel = (data) => post("/api/user/bindTel", data);
// 重新绑定手机号码
export const userResetTel = (data) => post("/api/user/resetTel", data);
// 用户相关
export const beBealer = (data) => post("/api/user/dealer", data);
export const selectFavorite = (data) => get("/api/user/favorite", data);
export const addFavorite = (data) => post("/api/user/addFavorite", data);



// 资讯
export const selectNewsAll = (data) => get("/api/news/selectAll", data);
export const selectNewsByIdOrName = (data) => get("/api/news/selectByIdOrName", data);


// 获取产品详情
export const getSidebar = (data) => get("/api/product/sidebar", data);
export const getProduct = (data) => get("/api/product/id", data);
export const selectProductById = (data) => get("/api/product/selectById", data);
export const selectProductByCategory = (data) => get("/api/product/selectProductByCategory", data);
export const select1Category = (data) => get("/api/product/oneCategory", data);
export const select2Category = (data) => get("/api/product/twoCategory", data);
export const select3Category = (data) => get("/api/product/threeCategory", data);
export const selectStyleCategory = (data) => get("/api/product/selectByStyle", data);


// banner
export const selectBanner = (data) => get("/api/banner/banner", data);





/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from "axios";
// import QS from "qs";
import {
  Toast,
  Dialog
} from "vant";
// import store from "../store/index";
// import "../lang/language.js";
import router from "../router"; 

import Cookie from 'vue-cookies'

if (process.env.NODE_ENV == "development") {
  // axios.defaults.baseURL = "http://60.205.210.127:443";
  axios.defaults.baseURL = "http://192.168.2.158:81";
} else if (process.env.NODE_ENV == "debug") {
  axios.defaults.baseURL = "http://192.168.2.158:81";
} else if (process.env.NODE_ENV == "production") {
  axios.defaults.baseURL = "https://m.mizun.cn/";
}
console.log(process)

// 请求超时时间
axios.defaults.timeout = 10000;

// post请求头
axios.defaults.headers.post["Content-Type"] =
  // application/x-www-form-urlencoded;charset=UTF-8
  "application/json;charset=utf-8" || "multipart/form-data";
// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // if (localStorage.getItem("Authorization")) {
    //   config.headers.token = localStorage.getItem("Authorization");
    // }
    if(Cookie.get('Authorization')){
      config.headers.token = Cookie.get('Authorization')
    }else{
      config.headers.token = ''
    }
    
    // config.headers.token = localStorage.getItem("Authorization")?localStorage.getItem("Authorization"):'';
    // config.headers.token = Cookie.get('Authorization')!=null? Cookie.get('Authorization') : '';
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// res => {
//     console.log(res)
//     // let resultData = res.data;
//     // console.log(resultData);
//     // return resultData
//     // if (resultData.code == 200 || resultData.code == 201 || resultData.code == 203) {
//     //   return resultData.data;
//     // } else {
//     //   let msg = resultData.msg ? resultData.msg : "系统出小差了";
//     //   return Promise.reject(msg);
//     // }
//   },
//   error => {
//     // Do something with response error
//     return Promise.reject("系统出小差了");
//   }
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    let resultData = response.data;
    
    if (resultData.code == 200 || resultData.code == 502) {
      return Promise.resolve(response);
    } else if (resultData.code === 503 || resultData.code === 5000 ) {
      console.log('失效');
      console.log(response);
      // alert(response);
      localStorage.removeItem("Authorization");
      // router.push("/");
      router.replace({
        path: "/login",
        query: {
          redirect: router.currentRoute.fullPath
        },
      });
    } else if (resultData.code === 6000 ) {
      console.log('绑定手机');
      console.log(response);
      
      router.replace({
        path: "/bindMobile",
        
      });
    } else if (resultData.code == 1100) {
      // console.log(router);
      router.push({
        path: "/realName",
      });
    } else if (resultData.code == 1102) {
      Dialog.confirm({
          title: '提示',
          message: resultData.message,
        })
        .then(() => {
          router.push({
            path: '/cashier',
            query: {
              orderNo: resultData.orderNo
            }
          })
        })
        .catch(() => {
        });
    } else {
      let msg = resultData.msg ? resultData.msg : resultData.message;
      Toast({
        message: msg,
        duration: 1000,
        forbidClick: true,
      });
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      /*网络连接过程异常处理*/
      let {
        message
      } = error;
      if (message == "Network Error") {
        message = "后端接口连接异常";
      }
      if (message.includes("timeout")) {
        message = "后端接口请求超时";
      }
      if (message.includes("Request failed with status code")) {
        message = "后端接口" + message.substr(message.length - 3) + "异常";
      }
      Toast({
        message: message || "后端接口未知异常",
        duration: 1500,
        forbidClick: true,
      });
      // errorMsg(message || "后端接口未知异常");
      return Promise.reject(error.response);
    }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
// function changeLan() {
//   let type = localStorage.getItem("lang");
//   if (localStorage.getItem("lang") == 0) {
//     new fontConversion({
//       autoChange: {
//         isAuo: true,
//         autoType: "fan_jian", //jian_fan-简体转换繁体，fan_jian-繁体转换简体
//       },
//     });
//   } else {
//     new fontConversion({
//       autoChange: {
//         isAuo: true,
//         autoType: "jian_fan", //jian_fan-简体转换繁体，fan_jian-繁体转换简体
//       },
//     });
//   }
//   localStorage.setItem("lang", type);
// }
// router.beforeEach((to, from, next) => {
//   next();
//   setTimeout(() => {
//     changeLan();
//   }, 10);
// });